.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.css-wgai2y-MuiFormLabel-asterisk, .MuiFormLabel-asterisk {
  color: red;
  font-weight: bold;
  font-size: 25px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button {
  margin: 10px;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 400;
  color: #fff;
  padding: 23px 50px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  border: 0px;
  cursor: pointer;
}
.green {
  background-color: rgb(6, 172, 0);
}
.red {
  background-color: rgb(221, 52, 66);
}
.red:disabled {
  background-color: rgb(193, 191, 191);
  cursor: not-allowed;
}
.cart {
  text-align: center;
}


.footer-btn-blk{
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 9;
}
.mrtop-5{
  margin-top: 5px;
}
.mrtop-10{
  margin-top: 10px;
}
.mrtop-15{
  margin-top: 15px;
}
.mrtop-20{
  margin-top: 20px;
}
.mrtop-25{
  margin-top: 25px;
}
.mrtop-30{
  margin-top: 30px;
}
.mrbtm-5{
  margin-bottom: 5px;
}
.mrbtm-10{
  margin-bottom: 10px;
}
.mrbtm-15{
  margin-bottom: 15px;
}
.mrbtm-20{
  margin-bottom: 20px;
}
.mrbtm-25{
  margin-bottom: 25px;
}
.mrbtm-30{
  margin-bottom: 30px;
}
.red{
  color: red;
}
.green{
  color:green;
}
.MuiAlert-message{
  width: 100%;
}
.react-draggable{
  position: absolute !important;
  white-space:nowrap;
 }
 .react-draggable.react-draggable-dragging { color: #ff0000; }

 .custom-scroll::-webkit-scrollbar {
  width: 8px;
}
 .custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #5e86ad;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #5e86ad;
}
.download-xls-button{
  display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    color: rgb(25, 118, 210);
    outline: 0px;
    margin: 0px;
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(25, 118, 210, 0.5);
    border-image: initial;
}
.print-letter-content-body{
  display: none;
}